// Third-party libraries
import React, { useEffect, useContext } from "react";
import toast from "react-hot-toast";
import { Link as DomLink } from "react-router-dom";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Stack,
  Button,
  Chip,
  Divider,
  Tooltip,
} from "@mui/material";

// Local modules
import { Mobile, Default } from "../../utils/deviceHelpers";
import styleCodes from "../../utils/styleCodes";
import { CartContext } from "../../utils/stateHandlers/contexts";
import { api, storage } from "../../utils";
import formatting from "../../utils/formatHelpers";
import parse from "html-react-parser";
import gaFunctions from "../../utils/GAComponents/GAFunctions";

export default function Cart(props) {
  const { cartState, cartDispatch } = useContext(CartContext);
  const { cart } = cartState;
  const cartId =
    storage.getStorageItem("cartId") !== "null"
      ? storage.getStorageItem("cartId")
      : JSON.parse(storage.getStorageItem("cartId"));
  const subtotal = cart.reduce((acc, item) => {
    if (item.invoices.length > 0) {
      return acc + Number(item.invoices[0].total);
    }
    return acc;
  }, 0);

  const gaViewCartInfo = {
    subtotal: subtotal,
    items: cart,
  };
  const isMill = (product) => {
    return styleCodes.millProducts.includes(product);
  };
  const notWireTubingFs = (product) => {
    const productCodes = [
      "GR",
      "SD",
      "SDC",
      "SR",
      "SRB",
      "FLX",
      "SDB",
      "ALL",
      "FS",
      "BUL",
      "DSK",
      "ML",
      "AE",
    ];
    return productCodes.includes(product);
  };

  const solderProduct = (product) => {
    const solderProductCodes = [
      ...["SD", "SDC", "SR", "SRB", "FLX", "SRC", "SDW"],
    ];
    return solderProductCodes.includes(product);
  };

  const coinBullionProduct = (product) => {
    const coinBullionProductCodes = [...["ML", "BUL", "DSK", "AE"]];
    return coinBullionProductCodes.includes(product);
  };

  const MobileLineItem = (lineItem) => (
    <Stack spacing={1} key={lineItem.id}>
      {lineItem.invoices.map((invoice, index) => (
        <React.Fragment key={index}>
          <Typography
            variant="headerFont"
            sx={{
              backgroundColor: "#f0f0f0",
              padding: "0.5rem",
            }}
          >
            {parse(invoice.specification)}
            <Tooltip title="Delete Line Item">
              <Chip
                className="float-right"
                label={<DeleteTwoToneIcon sx={{ verticalAlign: "middle" }} />}
                color="error"
                variant="outlined"
                onClick={() => deleteLineItem(lineItem)}
              />
            </Tooltip>
          </Typography>
          <Typography variant="headerFont">
            Ship Date: {lineItem.ship_info.ship_date}
          </Typography>
          <Typography variant="cellFont">
            Material: {invoice.material}
          </Typography>
          <Typography variant="cellFont">
            Quantity: {formatting["removeSpace"](invoice.quantity)}
          </Typography>
          <Typography variant="cellFont">
            Weight: {formatting["removeSpace"](invoice.weight)}
          </Typography>
          <Typography variant="cellFont">
            Unit Rate: {formatting["price2"](invoice.unit_price)}
          </Typography>
          <Typography variant="cellFont">
            Material Rate: {formatting["price2"](invoice.material_price)}
          </Typography>
          <Typography variant="cellFont">
            Labor/Unit: {formatting["price2"](invoice.labor_per_unit)}
          </Typography>
          <Typography variant="cellFont">
            Total Material: {formatting["price2"](invoice.total_material)}
          </Typography>
          <Typography variant="cellFont">
            Total Labor: {formatting["price2"](invoice.total_labor)}
          </Typography>
          <Typography variant="headerFont">
            Item Total: {formatting["price2"](invoice.total)}
          </Typography>
        </React.Fragment>
      ))}
      <Divider orientation="horizontal" flexItem />
    </Stack>
  );

  const SingleInvoiceLine = (lineItem) => (
    <React.Fragment key={lineItem.id}>
      {lineItem.invoices.map((invoice, index) => (
        <TableRow key={index}>
          <TableCell>
            <Typography variant="cellFont">
              {lineItem.ship_info.ship_date}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {lineItem.material === "D" && lineItem.finger_size !== "" ? (
                <DomLink
                  to={{ pathname: "/diamonds" }}
                  style={{ color: "#0492c2", textDecoration: "underline" }}
                >
                  Diamond
                </DomLink>
              ) : lineItem.material === "D" || lineItem.material === "LG" ? (
                <DomLink
                  to={{ pathname: "/melee-diamonds" }}
                  style={{ color: "#0492c2", textDecoration: "underline" }}
                >
                  Melee
                </DomLink>
              ) : solderProduct(lineItem.product) ? (
                <DomLink
                  to={{
                    pathname: `/group/SOLDER`,
                    state: {
                      isMill: false,
                      mtoConfiguratorProduct: false,
                      recentlyViewed: JSON.parse(
                        storage.getSessionStorageItem(
                          "hsRecentlyViewedProducts"
                        )
                      ),
                    },
                  }}
                  style={{ color: "#0492c2", textDecoration: "underline" }}
                >
                  {lineItem.dimension === "" ? "FLX" : lineItem.dimension}
                </DomLink>
              ) : coinBullionProduct(lineItem.product) ? (
                <DomLink
                  to={{
                    pathname: `/product/COINS BULLION`,
                    state: {
                      isMill: true,
                      mtoConfiguratorProduct: false,
                      recentlyViewed: JSON.parse(
                        storage.getSessionStorageItem(
                          "hsRecentlyViewedProducts"
                        )
                      ),
                    },
                  }}
                  style={{ color: "#0492c2", textDecoration: "underline" }}
                >
                  Coins & Bullion
                </DomLink>
              ) : isMill(lineItem.product) ||
                lineItem.product === "WAS" ||
                lineItem.product === "CIR" ||
                lineItem.product === "CHM" ? (
                <DomLink
                  to={{
                    pathname: `/product/${lineItem.product}`,
                    state: {
                      isMill: true,
                      showBAR: false,
                      mtoConfiguratorProduct: false,
                      recentlyViewed: JSON.parse(
                        storage.getSessionStorageItem(
                          "hsRecentlyViewedProducts"
                        )
                      ),
                    },
                  }}
                  style={{ color: "#0492c2", textDecoration: "underline" }}
                >
                  {lineItem.product}
                </DomLink>
              ) : lineItem.product === "SOL" ? (
                <DomLink
                  to={{
                    pathname: `/product/${lineItem.dimension}`,
                    state: {
                      isMill: false,
                      mtoConfiguratorProduct: false,
                      recentlyViewed: JSON.parse(
                        storage.getSessionStorageItem(
                          "hsRecentlyViewedProducts"
                        )
                      ),
                    },
                  }}
                  style={{ color: "#0492c2", textDecoration: "underline" }}
                >
                  {lineItem.dimension}
                </DomLink>
              ) : (
                <DomLink
                  to={{
                    pathname: `/product/${lineItem.product}${lineItem.dimension}`,
                    state: {
                      isMill: false,
                      mtoConfiguratorProduct: false,
                      recentlyViewed: JSON.parse(
                        storage.getSessionStorageItem(
                          "hsRecentlyViewedProducts"
                        )
                      ),
                    },
                  }}
                  style={{ color: "#0492c2", textDecoration: "underline" }}
                >
                  {lineItem.product}
                  {lineItem.dimension}
                </DomLink>
              )}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {parse(invoice.specification)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">{invoice.material}</Typography>
          </TableCell>
          <TableCell>
            {lineItem.editing ? (
              determineQuantityType(lineItem)
            ) : (
              <Typography variant="cellFont">
                {formatting["removeSpace"](invoice.quantity)}
              </Typography>
            )}
          </TableCell>
          <TableCell>
            {invoice.material !== "DIA" && invoice.material !== "LG" && (
              <>
                {lineItem.editing ? (
                  <Tooltip title="Save Quantity">
                    <Chip
                      label={
                        <SaveTwoToneIcon sx={{ verticalAlign: "middle" }} />
                      }
                      color="success"
                      variant="outlined"
                      sx={{ marginBottom: "0.5rem" }}
                      onClick={() => setSave(lineItem)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit Quantity">
                    <Chip
                      label={
                        <EditTwoToneIcon sx={{ verticalAlign: "middle" }} />
                      }
                      color="primary"
                      variant="outlined"
                      sx={{ marginBottom: "0.5rem" }}
                      onClick={() => setEditing(lineItem.id)}
                    />
                  </Tooltip>
                )}
              </>
            )}
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {formatting["removeSpace"](invoice.weight)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {invoice.unit_price === "0.00000"
                ? "---"
                : formatting["price2"](invoice.unit_price)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {invoice.material_price === "0.00000"
                ? "---"
                : formatting["price2"](invoice.material_price)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {invoice.labor_per_unit === "0.00000"
                ? "---"
                : formatting["price2"](invoice.labor_per_unit)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {invoice.total_material === "0.00000"
                ? "---"
                : formatting["price2"](invoice.total_material)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {invoice.total_labor === "0.00000"
                ? "---"
                : formatting["price2"](invoice.total_labor)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="headerFont">
              {invoice.total === "0.00000"
                ? "---"
                : formatting["price2"](invoice.total)}
            </Typography>
          </TableCell>
          <TableCell>
            <Tooltip title="Delete Line Item">
              <Chip
                label={<DeleteTwoToneIcon sx={{ verticalAlign: "middle" }} />}
                color="error"
                variant="outlined"
                onClick={() => deleteLineItem(lineItem)}
              />
            </Tooltip>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );

  const MultiInvoiceLine = (lineItem) => {
    const bandLine = lineItem.invoices.find((invoice) => {
      return (
        invoice.material !== "" &&
        invoice.material !== "LG" &&
        invoice.material !== "D"
      );
    });
    const diamondLine = lineItem.invoices.find((invoice) => {
      return invoice.material === "LG" || invoice.material === "D";
    });
    const totalPrice = lineItem.invoices.find((invoice) => {
      return invoice.material === "";
    }).total;
    return (
      <React.Fragment key={lineItem.id}>
        <TableRow>
          <TableCell>
            <Typography variant="cellFont">
              {lineItem.ship_info.ship_date}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              <DomLink
                to={{
                  pathname: `/product/${lineItem.product}${lineItem.dimension}`,
                  state: {
                    isMill: false,
                    mtoConfiguratorProduct:
                      lineItem.product === "WB" ? false : true,
                    recentlyViewed: JSON.parse(
                      storage.getSessionStorageItem("hsRecentlyViewedProducts")
                    ),
                  },
                }}
                style={{ color: "#0492c2", textDecoration: "underline" }}
              >
                {lineItem.product}
                {lineItem.dimension}
              </DomLink>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {parse(bandLine.specification)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">{bandLine.material}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {formatting["removeSpace"](bandLine.quantity)}
            </Typography>
          </TableCell>
          <TableCell />
          <TableCell>
            <Typography variant="cellFont">
              {formatting["removeSpace"](bandLine.weight)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {bandLine.unit_price === "0.00000"
                ? "---"
                : formatting["price2"](bandLine.unit_price)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {bandLine.material_price === "0.00000"
                ? "---"
                : formatting["price2"](bandLine.material_price)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {bandLine.labor_per_unit === "0.00000"
                ? "---"
                : formatting["price2"](bandLine.labor_per_unit)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {bandLine.total_material === "0.00000"
                ? "---"
                : formatting["price2"](bandLine.total_material)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="cellFont">
              {bandLine.total_labor === "0.00000"
                ? "---"
                : formatting["price2"](bandLine.total_labor)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="headerFont">
              {totalPrice === "0.00000"
                ? "---"
                : formatting["price2"](totalPrice)}{" "}
            </Typography>
          </TableCell>
          <TableCell>
            <Tooltip title="Delete Line Item">
              <Chip
                label={<DeleteTwoToneIcon sx={{ verticalAlign: "middle" }} />}
                color="error"
                variant="outlined"
                onClick={() => deleteLineItem(lineItem)}
              />
            </Tooltip>
          </TableCell>
        </TableRow>
        {diamondLine && (
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <Typography variant="cellFont">
                {parse(diamondLine.specification)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="cellFont">{diamondLine.material}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="cellFont">
                {formatting["removeSpace"](diamondLine.quantity)}
              </Typography>
            </TableCell>
            <TableCell />
            <TableCell>
              <Typography variant="cellFont">
                {formatting["removeSpace"](diamondLine.weight)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="cellFont">
                {diamondLine.unit_price === "0.00000"
                  ? "---"
                  : formatting["price2"](diamondLine.unit_price)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="cellFont">
                {diamondLine.material_price === "0.00000"
                  ? "---"
                  : formatting["price2"](diamondLine.material_price)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="cellFont">
                {diamondLine.labor_per_unit === "0.00000"
                  ? "---"
                  : formatting["price2"](diamondLine.labor_per_unit)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="cellFont">
                {diamondLine.total_material === "0.00000"
                  ? "---"
                  : formatting["price2"](diamondLine.total_material)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="cellFont">
                {diamondLine.total_labor === "0.00000"
                  ? "---"
                  : formatting["price2"](diamondLine.total_labor)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="cellFont"> </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  };

  function setEditing(lineItemId) {
    const lineItemToEdit = cart.find((cartItem) => cartItem.id === lineItemId);

    lineItemToEdit.editing = true;
    cartDispatch({
      type: "setCart",
      payload: cart,
    });
  }

  function determineQuantityType(lineItem) {
    if (lineItem.isMill) {
      const pieces = lineItem.quantity.split("-");
      return (
        <TextField
          size="small"
          onChange={(e) => setNewQuantity(lineItem, e.target.value)}
          InputProps={{
            endAdornment: (
              <Typography position="end">{`${pieces[1]}${lineItem.quantity_unit}`}</Typography>
            ),
          }}
          defaultValue={pieces[0]}
        />
      );
    } else {
      return (
        <TextField
          size="small"
          onChange={(e) => setNewQuantity(lineItem, e.target.value)}
          InputProps={{
            endAdornment: (
              <Typography position="end">{lineItem.quantity_unit}</Typography>
            ),
          }}
          defaultValue={lineItem.quantity}
        />
      );
    }
  }

  function setNewQuantity(lineItem, value) {
    const lineItemToEdit = cart.find((cartItem) => cartItem.id === lineItem.id);
    lineItemToEdit.newQuantity = value;
    cartDispatch({
      type: "setCart",
      payload: cart,
    });
  }

  function setSave(lineItem) {
    if (lineItem.newQuantity !== 0) {
      const pieces = lineItem.isMill ? lineItem.quantity.split("-") : "";
      const editLineItemBody = {
        cart_id:
          storage.getStorageItem("cartId") === "null" ||
          storage.getStorageItem("cartId") === "undefined"
            ? localStorage.removeItem("cartId")
            : storage.getStorageItem("cartId"),
        id: lineItem.id,
        partcode: {
          karat: lineItem.material,
          product: lineItem.product,
          dimension: lineItem.dimension,
          finger_size: lineItem.finger_size,
          quantity: lineItem.isMill
            ? `${lineItem.newQuantity}-${pieces[1]}`
            : lineItem.newQuantity,
          quantity_unit: lineItem.quantity_unit,
          carat_size: lineItem.carat_size,
          custom_dimension: {
            sale: 1,
            terms: 1,
          },
          treatments: lineItem.treatments,
        },
      };
      api.post("cart/edit", editLineItemBody).then((response) => {
        if (response.isAxiosError) {
          const errorMessage = [];
          response.data.errors.forEach(function (error) {
            errorMessage.push(error);
          });
          toast.error(
            `There was an error processing your request. ${errorMessage.toString()}`
          );
        } else {
          const lineItemToEdit = cart.find(
            (cartItem) => cartItem.id === lineItem.id
          );
          const editedLineItem = response.data.items.find(
            (newLineItem) => newLineItem.id === lineItem.id
          );
          lineItem.quantity = editedLineItem.quantity;
          lineItem.invoices = editedLineItem.invoices;
          lineItem.ship_info = editedLineItem.ship_info;
          lineItemToEdit.editing = false;
          lineItemToEdit.newQuantity = 0;
          cartDispatch({
            type: "setCart",
            payload: cart,
          });
        }
      });
    } else {
      const lineItemToEdit = cart.find(
        (cartItem) => cartItem.id === lineItem.id
      );
      lineItemToEdit.editing = false;
      cartDispatch({
        type: "setCart",
        payload: cart,
      });
    }
  }

  function deleteLineItem(lineItem) {
    const toDelete = {
      cart_id: storage.getStorageItem("cartId"),
      id: lineItem.id,
    };
    api.post("cart/remove", toDelete).then((res) => {
      cartDispatch({
        type: "updateCart",
        payload: {
          cart: res.data.items,
          cartCount: res.data.count,
        },
      });
      gaFunctions.gaRemoveFromCart(lineItem);
    });
  }

  function mapLineItems(items) {
    const newItems = [];
    items.forEach(function (item) {
      const quantityType =
        isMill(item.product) && !notWireTubingFs(item.product);
      const newItem = {
        ...item,
        editing: false,
        newQuantity: 0,
        isMill: quantityType,
      };
      newItems.push(newItem);
    });
    return newItems;
  }

  useEffect(() => {
    api.fetch("cart/check?cart_id=", cartId).then((response) => {
      cartDispatch({
        type: "setCart",
        payload: mapLineItems(response.data.items),
      });
      cartDispatch({ type: "setCartCount", payload: response.data.count });
      gaFunctions.gaViewCart(gaViewCartInfo);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CartContext.Provider value={{ cartState, cartDispatch }}>
      <Mobile>
        <Stack spacing={3}>
          <Typography variant="h1">Shopping Cart</Typography>
          {cart && cart.length === 0 && (
            <Typography>Your cart is empty!</Typography>
          )}
          {cart && cart.length > 0 && (
            <>
              {cart && cart.map((lineItem) => MobileLineItem(lineItem))}
              <div style={{ marginRight: "9.5rem" }}>
                <Typography
                  variant="cellFont"
                  className="float-right"
                  sx={{ fontWeight: "800" }}
                >
                  Subtotal: {formatting["price2"](subtotal)}
                </Typography>
              </div>
              <DomLink to={{ pathname: "/checkout" }}>
                <Button variant="contained" color="success">
                  Checkout
                </Button>
              </DomLink>
            </>
          )}
        </Stack>
      </Mobile>
      <Default>
        <Stack spacing={3}>
          <Typography variant="h1">Shopping Cart</Typography>
          {cart && cart.length === 0 && (
            <Typography>Your cart is empty!</Typography>
          )}
          {cart && cart.length > 0 && (
            <>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="headerFont">Ship Date</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headerFont">Product</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headerFont">
                        Specifications
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headerFont">Material</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headerFont">Quantity</Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell>
                      <Typography variant="headerFont">Weight</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headerFont">Unit Rate</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headerFont">
                        Material Rate
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headerFont">Labor/Unit</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headerFont">
                        Total Material
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headerFont">Total Labor</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headerFont">Total</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headerFont">Actions</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {cart &&
                  cart.map((lineItem) => (
                    <TableBody key={lineItem.id}>
                      {lineItem.invoices.length === 3
                        ? MultiInvoiceLine(lineItem)
                        : SingleInvoiceLine(lineItem)}
                    </TableBody>
                  ))}
              </Table>
              <div style={{ marginRight: "9.5rem" }}>
                <Typography
                  variant="cellFont"
                  className="float-right"
                  sx={{ fontWeight: "800" }}
                >
                  Subtotal: {formatting["price2"](subtotal)}
                </Typography>
              </div>
              <div style={{ marginRight: "9.5rem" }}>
                <DomLink to={{ pathname: "/checkout" }}>
                  <Button
                    variant="contained"
                    color="success"
                    className="float-right"
                  >
                    Checkout
                  </Button>
                </DomLink>
              </div>
            </>
          )}
        </Stack>
      </Default>
    </CartContext.Provider>
  );
}
