// Third-party libraries
import React, { useContext, useState, useEffect } from "react";
import { Link as DomLink, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import HelpIcon from "@mui/icons-material/Help";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CameraAlt from "@mui/icons-material/CameraAlt";

import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import {
  Box,
  Chip,
  Button,
  Grid,
  Stack,
  Typography,
  Divider,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Local modules
import { Default } from "../../utils/deviceHelpers";
import { DiamondSearchContext } from "../../utils/stateHandlers/contexts";
import ShapeFilter from "./controls/filters/ShapeFilter";
import Modal from "./controls/Modal";
import { Checkboxes, Range, RangeNominal } from "./controls/filters";
import { DiamondColors } from "./DiamondColors";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 34,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "& + .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#939598",
    },
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#7fa04d",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    boxSizing: "border-box",
  },
}));

export default function CertDiamondFilters(props) {
  const { diamondState, dispatch } = useContext(DiamondSearchContext);
  const {
    filters,
    advancedFilters,
    showAdvancedFilters,
    searchQuery,
    tableHeaders,
    resetFilters,
    openHelpText,
    helpTextLabel,
    showFancyColorFilter,
    helpText,
    fancyColorFilters,
  } = diamondState;

  const [showHelp, setShowHelp] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [sameDayToggle, setSameDayToggle] = useState(false);
  const [imageToggle, setImageToggle] = useState(false);

  const sendTo = useLocation();

  const {
    updateCheckedBoxes,
    switchToFancyColors,
    updateNominalRange,
    valueForColor,
    updateRange,
    loggedIn,
    clearFilters,
    setLocalFcIntensity,
  } = props;

  const MinedOrLab = () => {
    const helpText = tableHeaders.find(function (header) {
      return header.name === "MinedOrLab";
    });

    return (
      <Grid container>
        <Grid item className="mined-filter" sx={{ width: "100%" }}>
          <Button
            disableElevation
            variant="contained"
            color={valueForColor === "M" ? "primary" : "secondary"}
            onClick={() => {
              clearFilters({
                name: "MinedOrLab",
                values: [{ name: "M" }],
              });
            }}
          >
            Mined
          </Button>
          <Button
            disableElevation
            variant="contained"
            color={valueForColor === "L" ? "success" : "secondary"}
            onClick={() => {
              clearFilters({
                name: "MinedOrLab",
                values: [{ name: "L" }],
              });
            }}
          >
            Lab-Grown
          </Button>
          <HelpIcon
            sx={{ marginLeft: "10px" }}
            className="help-icon question-mark"
            onClick={() => setShowHelp(true)}
          />
        </Grid>
        <Modal
          title={"Mined Or Lab-Grown"}
          onClose={() => setShowHelp(false)}
          show={showHelp}
        >
          {filters.length > 0 ? parse(helpText.help_text) : ""}
        </Modal>
      </Grid>
    );
  };

  useEffect(() => {
    setLocalFcIntensity(["None"]);
    setSameDayToggle(false);
    setImageToggle(false);
    dispatch({
      type: "setShowFancyColorFilter",
      payload: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFilters]);

  return (
    <Default>
      <Stack spacing={1} alignItems="center" sx={{ marginBottom: "2rem" }}>
        {MinedOrLab()}
        <Typography>
          Need help finding the perfect diamond? &nbsp;
          <span
            className="contact-us pointer"
            onClick={() => setShowContactModal(true)}
          >
            Contact Us
          </span>
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              sm: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
              xl: "repeat(2, 1fr)",
            },
            paddingTop: "2rem",
          }}
        >
          {filters &&
            filters.map((filter) => (
              <Stack
                key={filter.name}
                direction="row"
                spacing={2}
                sx={{ marginLeft: "2rem", marginBottom: "1.5rem" }}
              >
                <Typography
                  sx={{
                    width: "12%",
                    paddingTop:
                      filter.label === "Source" ||
                      filter.label === "Features" ||
                      filter.label === "Report"
                        ? "2.8rem"
                        : ".6rem",
                    textTransform: "uppercase",
                  }}
                >
                  <Stack>
                    {filter.name === "HooverPrice" ? (
                      "Price"
                    ) : (
                      <Stack direction="row">
                        {filter.label}
                        {filter.label === "Source" ||
                        filter.label === "Features" ? (
                          <DomLink
                            to={{
                              pathname: "/sub-landing/Diamond-Sources",
                            }}
                            target="_blank"
                          >
                            <HelpIcon className="help-icon question-mark" />
                          </DomLink>
                        ) : (
                          <>
                            {filter.name !== "HooverPrice" && (
                              <HelpIcon
                                className="help-icon question-mark"
                                onClick={() =>
                                  dispatch({
                                    type: "setOpenHelpText",
                                    payload: {
                                      open: true,
                                      label: filter.label,
                                      text: parse(filter.help_text),
                                    },
                                  })
                                }
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    )}
                  </Stack>
                </Typography>
                <Box
                  component="span"
                  sx={{
                    paddingLeft: "3rem",
                    paddingRight: "3rem",
                    width: "93%",
                  }}
                >
                  {filter.type === "options" && filter.label === "Shape" ? (
                    <ShapeFilter />
                  ) : filter.type === "options" && filter.label !== "Shape" ? (
                    <Checkboxes
                      search={searchQuery}
                      filter={filter}
                      resetFilters={resetFilters}
                      updateCheckedBoxes={updateCheckedBoxes}
                    />
                  ) : filter.type === "enum" && filter.name === "Color" ? (
                    <>
                      <DiamondColors
                        switchToFancyColors={switchToFancyColors}
                      />
                      {showFancyColorFilter ? (
                        <RangeNominal
                          filter={fancyColorFilters[1]}
                          resetFilters={resetFilters}
                          updateNominalRange={updateNominalRange}
                          color={valueForColor === "M" ? "primary" : "success"}
                        />
                      ) : (
                        <RangeNominal
                          filter={filter}
                          resetFilters={resetFilters}
                          updateNominalRange={updateNominalRange}
                          color={valueForColor === "M" ? "primary" : "success"}
                        />
                      )}
                    </>
                  ) : filter.type === "enum" ? (
                    <RangeNominal
                      filter={filter}
                      resetFilters={resetFilters}
                      updateNominalRange={updateNominalRange}
                      color={valueForColor === "M" ? "primary" : "success"}
                    />
                  ) : filter.type === "numeric" &&
                    filter.name !== "HooverPrice" ? (
                    <Range
                      filter={filter}
                      resetFilters={resetFilters}
                      updateRange={updateRange}
                      color={valueForColor === "M" ? "primary" : "success"}
                    />
                  ) : filter.name === "HooverPrice" && !loggedIn ? (
                    <DomLink
                      to={{
                        pathname: "/login",
                        state: { sendTo: sendTo.pathname },
                      }}
                    >
                      <Button variant="contained" color="error">
                        Login For pricing
                      </Button>
                    </DomLink>
                  ) : filter.name === "HooverPrice" && loggedIn ? (
                    <Range
                      filter={filter}
                      resetFilters={resetFilters}
                      updateRange={updateRange}
                      color={valueForColor === "M" ? "primary" : "success"}
                    />
                  ) : null}
                </Box>
              </Stack>
            ))}
          {showAdvancedFilters && (
            <>
              {advancedFilters &&
                advancedFilters.map((filter) => (
                  <Stack
                    key={filter.name}
                    direction="row"
                    spacing={2}
                    sx={{ marginLeft: "2rem", marginBottom: "1.5rem" }}
                  >
                    <Typography
                      sx={{
                        width: "12%",
                        paddingTop: ".6rem",
                        textTransform: "uppercase",
                      }}
                    >
                      {filter.label}
                      {filter.label === "Source" ||
                      filter.label === "Features" ? (
                        <a
                          href={
                            process.env.REACT_APP_PHP_SITE_URL +
                            "diamond-sources"
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <HelpIcon className="help-icon question-mark" />
                        </a>
                      ) : (
                        <>
                          {filter.label !== "Price" && (
                            <HelpIcon
                              className="help-icon question-mark"
                              onClick={() =>
                                dispatch({
                                  type: "setOpenHelpText",
                                  payload: {
                                    open: true,
                                    label: filter.label,
                                    text: parse(filter.help_text),
                                  },
                                })
                              }
                            />
                          )}
                        </>
                      )}
                    </Typography>
                    <Box
                      component="span"
                      sx={{
                        paddingLeft: "3rem",
                        paddingRight: "3rem",
                        width: "93%",
                      }}
                    >
                      {filter.type === "options" && filter.label === "Shape" ? (
                        <ShapeFilter />
                      ) : filter.type === "options" &&
                        filter.label !== "Shape" ? (
                        <Checkboxes
                          search={searchQuery}
                          filter={filter}
                          resetFilters={resetFilters}
                          updateCheckedBoxes={updateCheckedBoxes}
                        />
                      ) : filter.type === "enum" ? (
                        <RangeNominal
                          filter={filter}
                          resetFilters={resetFilters}
                          updateNominalRange={updateNominalRange}
                          color={valueForColor === "M" ? "primary" : "success"}
                        />
                      ) : filter.type === "numeric" ? (
                        <Range
                          filter={filter}
                          resetFilters={resetFilters}
                          updateRange={updateRange}
                          color={valueForColor === "M" ? "primary" : "success"}
                        />
                      ) : null}
                    </Box>
                  </Stack>
                ))}
            </>
          )}
          <Stack direction="row" spacing={1} sx={{ marginLeft: "15rem" }}>
            {searchQuery.fields.find((f) => f.name === "MinedOrLab").values[0]
              .name === "L" && (
              <FormControlLabel
                control={
                  <CustomSwitch
                    id="ShipsSameDay"
                    type="checkbox"
                    checked={sameDayToggle}
                    onClick={(e) => {
                      updateCheckedBoxes("ShipsSameDay", {
                        name: "ShipsSameDay",
                        checked: e.target.checked ? "Y" : "N",
                      });
                      setSameDayToggle(e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography variant="subtitle1" sx={{ marginLeft: "2rem" }}>
                    Ships Same Day <LocalShippingTwoToneIcon color="error" />
                  </Typography>
                }
              />
            )}
            <FormControlLabel
              control={
                <CustomSwitch
                  id="ImageVideoAvailable"
                  type="checkbox"
                  sx={{ marginLeft: "1rem" }}
                  checked={imageToggle}
                  onClick={(e) => {
                    updateCheckedBoxes("ImageVideoAvailable", {
                      name: "ImageVideoAvailable",
                      checked: e.target.checked ? "Y" : "N",
                    });
                    setImageToggle(e.target.checked);
                  }}
                />
              }
              label={
                <Typography
                  variant="body3"
                  sx={{ fontWeight: "800", marginLeft: "2rem" }}
                >
                  Image/Video Available <CameraAlt color="primary" />
                </Typography>
              }
            />
          </Stack>
        </Box>
        <Divider flexItem>
          {!showAdvancedFilters ? (
            <Chip
              color={valueForColor === "M" ? "primary" : "success"}
              label="Show More Filters"
              icon={<ArrowDropDownIcon />}
              onClick={() =>
                dispatch({ type: "showAdvancedFilters", payload: true })
              }
            />
          ) : (
            <Chip
              color={valueForColor === "M" ? "primary" : "success"}
              label="Show Less Filters"
              icon={<ArrowDropUpIcon />}
              onClick={() =>
                dispatch({ type: "showAdvancedFilters", payload: false })
              }
            />
          )}
        </Divider>

        <Modal
          title={helpTextLabel}
          onClose={() =>
            dispatch({
              type: "setOpenHelpText",
              payload: {
                open: false,
                label: "",
                text: "",
              },
            })
          }
          show={openHelpText}
        >
          {helpText}
        </Modal>
        <Modal
          title="Contact Us"
          onClose={() => setShowContactModal(false)}
          show={showContactModal}
        />
      </Stack>
    </Default>
  );
}
