// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useEffect, useState } from "react";
import { Link as DomLink } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  Button,
  Container,
  Card,
  CardMedia,
  Grid,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
} from "@mui/material";

// Local modules
import api from "../../../utils/api";
import { constants } from "../../../utils";
import { storage } from "../../../utils";
import LandingFooter from "./LandingFooter";
import useMediaQueryUtils from "../../../utils/mediaQueryUtils";
import LeftCopyBox from "./styles/LeftCopyBox";
import RightCopyBox from "./styles/RightCopyBox";

const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

export default function AccordionStaticLanding(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [banner, setBanner] = useState();
  const [topgreen, setTopGreen] = useState();
  const [textblock, setTextBlock] = useState();
  const [textblock2, setTextBlock2] = useState();
  const [threeimages, setThreeImages] = useState();
  const [accordianboxes, setAccordianBoxes] = useState();
  const [bottomgreen, setBottomGreen] = useState();
  const [icons, setIcons] = useState();
  const isMdScreen = useMediaQueryUtils("md");

  const page =
    props.match.params.PageType === "Contract-Casting" ? constants.CASTING : 1;

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = qs.stringify({
      populate: {
        banner: {
          populate: "*",
        },
        topgreen: {
          populate: "*",
        },
        textblock: {
          populate: "*",
        },
        textblock2: {
          populate: "*",
        },
        imageblock: {
          populate: "*",
        },
        accordianBoxes: {
          populate: "*",
          accordians: {
            populate: "*",
          },
        },
        bottomgreen: {
          populate: "*",
        },
        icons: {
          populate: "*",
        },
      },
    });
    api
      .fetchStrapi(`/accordian-landing-pages/${page}?${query}`)
      .then((response) => {
        setBanner(
          response.data.data.attributes.banner.data
            ? response.data.data.attributes.banner.data.attributes
            : null
        );
        setTopGreen(response.data.data.attributes.topgreen);
        setTextBlock(response.data.data.attributes.textblock);
        setTextBlock2(response.data.data.attributes.textblock2);
        setThreeImages(response.data.data.attributes.imageblock);
        setAccordianBoxes(response.data.data.attributes.accordianBoxes);
        setBottomGreen(response.data.data.attributes.bottomgreen);
        setIcons(response.data.data.attributes.icons);
        setIsLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.PageType]);

  return (
    <>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl" className="careers-text">
          <Stack spacing={3} alignItems="center">
            {banner && (
              <img
                src={`${frontImageUrl}${banner.formats.large.url}`}
                width="100%"
                alt="banner"
              />
            )}
            {topgreen && (
              <Typography variant="greenRectangle" align="center">
                {topgreen.text}
              </Typography>
            )}
            {threeimages.map((threeimages) => (
              <Stack
                direction={isMdScreen ? "column" : "row"}
                key={threeimages.id}
                spacing={2}
              >
                <DomLink
                  to={{
                    pathname: `${threeimages.image1.data.attributes.alternativeText}`,
                  }}
                >
                  <Card className="pointer" sx={{ backgroundColor: "#e3e3e3" }}>
                    <CardMedia
                      component="img"
                      image={`${frontImageUrl}${threeimages.image1.data.attributes.url}`}
                    />
                    <Typography
                      variant="imageCaption"
                      align="center"
                      className="center"
                    >
                      {threeimages.image1.data.attributes.caption}
                    </Typography>
                  </Card>
                </DomLink>
                <DomLink
                  to={{
                    pathname: `${threeimages.image2.data.attributes.alternativeText}`,
                    state: {
                      recentlyViewed: JSON.parse(
                        storage.getSessionStorageItem(
                          "hsRecentlyViewedProducts"
                        )
                      ),
                    },
                  }}
                >
                  <Card className="pointer" sx={{ backgroundColor: "#e3e3e3" }}>
                    <CardMedia
                      component="img"
                      image={`${frontImageUrl}${threeimages.image2.data.attributes.url}`}
                    />
                    <Typography variant="imageCaption" className="center">
                      {threeimages.image2.data.attributes.caption}
                    </Typography>
                  </Card>
                </DomLink>
                <DomLink
                  to={{
                    pathname: `${threeimages.image3.data.attributes.alternativeText}`,
                    state: {
                      recentlyViewed: JSON.parse(
                        storage.getSessionStorageItem(
                          "hsRecentlyViewedProducts"
                        )
                      ),
                    },
                  }}
                >
                  <Card className="pointer" sx={{ backgroundColor: "#e3e3e3" }}>
                    <CardMedia
                      component="img"
                      image={`${frontImageUrl}${threeimages.image3.data.attributes.url}`}
                    />
                    <Typography variant="imageCaption" className="center">
                      {threeimages.image3.data.attributes.caption}
                    </Typography>
                  </Card>
                </DomLink>
              </Stack>
            ))}
            <Stack spacing={3}>
              <Typography variant="articleTitle" align="center">
                {textblock.title}
              </Typography>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ paddingRight: "2.5rem", marginBottom: "1rem" }}
                >
                  <Stack spacing={1}>
                    {textblock.leftimage.data &&
                      textblock.leftimageplacement === "top" && (
                        <img
                          src={`${frontImageUrl}${textblock.leftimage.data.attributes.url}`}
                          width="100%"
                          alt="banner"
                        />
                      )}
                    <Typography variant="staticText" textAlign={"center"}>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {textblock.leftcolumn}
                      </ReactMarkdown>
                    </Typography>
                    {textblock.leftbutton && (
                      <DomLink
                        to={{ pathname: `${textblock.leftbutton.to}` }}
                        className="center"
                      >
                        <Button variant="contained">
                          {textblock.leftbutton.text}
                        </Button>
                      </DomLink>
                    )}
                    {textblock.leftimage.data &&
                      textblock.leftimageplacement === "bottom" && (
                        <img
                          src={`${frontImageUrl}${textblock.leftimage.data.attributes.url}`}
                          width="100%"
                          alt="banner"
                        />
                      )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    paddingTop: "4rem",
                    paddingLeft: "4rem",
                    paddingRight: "4rem",
                    backgroundColor: "#cfdde9",
                    marginBottom: "1rem",
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    marginBottom="1rem"
                  >
                    {textblock.rightimage.data &&
                      textblock.rightimageplacement === "top" && (
                        <img
                          src={`${frontImageUrl}${textblock.rightimage.data.attributes.url}`}
                          width="100%"
                          alt="banner"
                        />
                      )}
                    <Typography variant="staticText" textAlign={"center"}>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {textblock.rightcolumn}
                      </ReactMarkdown>
                    </Typography>
                    {textblock.rightbutton && (
                      <DomLink
                        to={{ pathname: `${textblock.rightbutton.to}` }}
                        className="center"
                      >
                        <Button variant="contained">
                          {textblock.rightbutton.text}
                        </Button>
                      </DomLink>
                    )}
                    {textblock.rightimage.data &&
                      textblock.rightimageplacement === "bottom" && (
                        <img
                          src={`${frontImageUrl}${textblock.rightimage.data.attributes.url}`}
                          width="100%"
                          alt="banner"
                        />
                      )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            {accordianboxes.map((copyBox) => (
              <React.Fragment key={copyBox.id}>
                {copyBox.alignment === "left"
                  ? LeftCopyBox(copyBox, true)
                  : RightCopyBox(copyBox, true)}
              </React.Fragment>
            ))}
            <Stack
              spacing={3}
              sx={{
                backgroundColor: "#cfdde9",
                paddingTop: "4rem",
                paddingBottom: "4rem",
                paddingLeft: "6rem",
                paddingRight: "6rem",
              }}
            >
              <Typography variant="articleTitle" align="center">
                {textblock2.title}
              </Typography>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    {textblock2.leftimageplacement === "top" && (
                      <img
                        src={`${frontImageUrl}${textblock2.leftimage.data.attributes.url}`}
                        width="100%"
                        alt="banner"
                      />
                    )}
                    <Typography variant="staticText">
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {textblock2.leftcolumn}
                      </ReactMarkdown>
                    </Typography>
                    {textblock2.leftbutton && (
                      <DomLink
                        to={{ pathname: `${textblock2.leftbutton.to}` }}
                        className="center"
                      >
                        <Button variant="contained">
                          {textblock2.leftbutton.text}
                        </Button>
                      </DomLink>
                    )}
                    {textblock2.leftimageplacement === "bottom" && (
                      <img
                        src={`${frontImageUrl}${textblock2.leftimage.data.attributes.url}`}
                        width="100%"
                        alt="banner"
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack>
                    {textblock2.rightimageplacement === "top" && (
                      <img
                        src={`${frontImageUrl}${textblock2.rightimage.data.attributes.url}`}
                        width="100%"
                        alt="banner"
                      />
                    )}
                    <Typography variant="staticText">
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {textblock2.rightcolumn}
                      </ReactMarkdown>
                    </Typography>
                    {textblock2.rightbutton && (
                      <DomLink
                        to={{ pathname: `${textblock2.rightbutton.to}` }}
                        className="center"
                      >
                        <Button variant="contained">
                          {textblock2.rightbutton.text}
                        </Button>
                      </DomLink>
                    )}
                    {textblock2.rightimageplacement === "bottom" && (
                      <img
                        src={`${frontImageUrl}${textblock2.rightimage.data.attributes.url}`}
                        width="100%"
                        alt="banner"
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Typography variant="greenRectangle" align="center">
              {bottomgreen.text}
            </Typography>
            <LandingFooter icons={icons} />
          </Stack>
        </Container>
      )}
    </>
  );
}
