// Third-party libraries
import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Alert,
  Skeleton,
  Tooltip,
} from "@mui/material";

// Local modules
import {
  AppContext,
  CartContext,
  ProductsContext,
} from "../../../utils/stateHandlers/contexts";
import { api, storage } from "../../../utils";
import formatting from "../../../utils/formatHelpers";
import GAButton from "../../../utils/GAComponents/GAButton";
import gaFunctions from "../../../utils/GAComponents/GAFunctions";
import CartModal from "../../user/CartModal";
import LoginModal from "../../user/LoginModal";

export default function Price(props) {
  const { productState, productDispatch } = useContext(ProductsContext);
  const { showCustomMetalMarkets, setShowCustomMetalMarkets, authToken } =
    useContext(AppContext);
  const { cartDispatch } = useContext(CartContext);
  const [openCartmodal, setOpenCartModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const {
    priceBreaks,
    productInfo,
    estimatedShipDate,
    message,
    messageType,
    priceLoaded,
    productDetails,
    specialInstructions,
    charmDimension,
    circleDimension,
    validationErrors,
  } = productState;

  const schema = yup.object().shape({
    product: yup.string().required(),
    dimension: yup
      .string()
      .test(
        "dimension-not-selected",
        "Dimension/Size has not been selected.",
        (value) => value !== "None"
      )
      .nullable(),
    quantity: yup
      .string()
      // .test("quantity-is-integer", "Quantity Must Be An Integer", (value) => {
      //   if (
      //     !props.isMill &&
      //     productInfo.product !== "CH" &&
      //     productInfo.product !== "FCH" &&
      //     productInfo.product !== "CIR"
      //   ) {
      //     return _.isInteger(value);
      //   } else {
      //     return true;
      //   }
      // })
      .required("Quantity is Required"),
    quantity_unit: yup
      .string()
      .test(
        "quantity-unit-not-selected",
        "Quantity Unit has not been selected.",
        (value) => value !== "None"
      )
      .nullable(),
    material: yup.string().required("Material is Required"),
    carat_size: yup
      .string()
      .test(
        "carat_size-not-selected",
        "Carat Size has not been selected.",
        (value) => value !== "None"
      )
      .nullable(),
    outer_diameter: yup
      .string()
      .test(
        "outer_diameter-not-selected",
        "Outer Diameter has not been selected.",
        (value) => value !== "None"
      )
      .nullable(),
    inner_diameter: yup
      .string()
      .test(
        "inner_diameter-not-selected",
        "Inner Diameter has not been selected.",
        (value) => value !== "None"
      )
      .nullable(),
    message: yup.string().nullable(),
    finger_size: yup
      .string()
      .test(
        "finger_size-not-selected",
        "Ring Size has not been selected.",
        (value) => value !== "None"
      )
      .nullable(),
    treatments: yup.string().nullable(),
    // length: yup.number().min(0, "Length Cannot Be Less Than 0").nullable(),
    // length_unit: yup.string().nullable(),
    // width: yup.number().min(0, "Width Cannot Be Less Than 0").nullable(),
    // width_unit: yup.string().nullable(),
    thickness: yup.number().integer("Thickness Cannot Be A Decimal").nullable(),
    // thickness_unit: yup.string().nullable(),
  });

  function findBreakNumber(value) {
    const breakNumber =
      value < 10
        ? 10
        : value < 50
        ? 50
        : value < 100
        ? 100
        : value < 500
        ? 500
        : 0;
    return breakNumber;
  }

  function roundToTenth(number) {
    return Math.round(number * 10) / 10;
  }

  function createPriceBreaksTable(quantity) {
    const dwt = productDetails.find(function (detail) {
      return detail.label === "DWT";
    });
    const oz = productDetails.find(function (detail) {
      return detail.label === "OZ";
    });
    const someMath =
      dwt && props.isMill
        ? findBreakNumber(parseFloat(dwt.value)) - parseFloat(dwt.value)
        : oz && props.isMill
        ? findBreakNumber(parseFloat(oz.value)) - parseFloat(oz.value)
        : findBreakNumber(quantity) - quantity;
    return (
      <Typography
        sx={{
          fontFamily: "Verdana",
          textAlign: "center",
          fontSize: "1.4rem",
          fontWeight: "800",
          padding: "1rem",
          marginBottom: ".5rem",
          marginTop: ".5rem",
          backgroundColor: "#cfdde9",
        }}
      >
        Save $$$ - Add {roundToTenth(someMath)} more to reach the next quantity
        price break.
      </Typography>
    );
  }

  function checkWasherDimensions() {
    const outer = parseFloat(productInfo.outer_diameter);
    const inner = parseFloat(productInfo.inner_diameter);
    if (inner > outer) {
      return `${productInfo.inner_diameter}x${productInfo.outer_diameter}x${productInfo.thickness}${productInfo.thickness_unit}`;
    } else {
      return `${productInfo.outer_diameter}x${productInfo.inner_diameter}x${productInfo.thickness}${productInfo.thickness_unit}`;
    }
  }

  function createDimensionInfo() {
    if (productInfo.product === "FS") {
      return `${productInfo.length}${productInfo.length_unit}x${productInfo.width}${productInfo.width_unit}x${productInfo.thickness}${productInfo.thickness_unit}`;
    } else if (productInfo.product === "CIR") {
      return `${circleDimension}x${productInfo.thickness}${productInfo.thickness_unit}`;
    } else if (productInfo.product === "WAS") {
      return checkWasherDimensions();
    } else if (productInfo.product === "CHM") {
      return `${charmDimension}x${productInfo.thickness}${productInfo.thickness_unit}`;
    } else {
      return productInfo.dimension;
    }
  }

  function loginForPricing() {
    productDispatch({
      type: "setOpenLoginModal",
      payload: true,
    });
  }

  function addToCart() {
    setErrorMessages([]);
    const treatmentsList = (productInfo.treatments || []).map(function (
      treatment
    ) {
      return treatment.value;
    });
    const treatmentsString = treatmentsList.join(" ");

    const addToCartBody = {
      cart_id:
        storage.getStorageItem("cartId") === "null" ||
        storage.getStorageItem("cartId") === "undefined"
          ? localStorage.removeItem("cartId")
          : storage.getStorageItem("cartId"),
      partcode: {
        karat: productInfo.material,
        product: productInfo.product,
        dimension: createDimensionInfo(),
        finger_size: productInfo.finger_size,
        quantity:
          props.ProductGroup === "WireTubing"
            ? `${productInfo["quantity.pieces"]}-${productInfo["quantity.length"]}`
            : productInfo.quantity,
        quantity_unit:
          props.ProductGroup === "WireTubing"
            ? productInfo["quantity.length_unit"]
            : productInfo.quantity_unit,
        carat_size: productInfo.carat_size,
        custom_dimension: {
          sale: 1,
          terms: 1,
        },
        message: specialInstructions,
        treatments: treatmentsString,
      },
    };
    console.log("🚀 ~ addToCart ~ addToCartBody:", addToCartBody);
    const gaItemInfo = {
      item: addToCartBody.partcode,
      price: productState.price,
    };
    schema
      .validate({
        product: productInfo.product,
        dimension: productInfo.dimension,
        quantity:
          props.ProductGroup === "WireTubing"
            ? `${productInfo["quantity.pieces"]}-${productInfo["quantity.length"]}`
            : productInfo.quantity,
        quantity_unit:
          props.ProductGroup === "WireTubing"
            ? productInfo["quantity.length_unit"]
            : productInfo.quantity_unit,
        material: productInfo.material,
        carat_size: productInfo.carat_size,
        finger_size: productInfo.finger_size,
        outer_diameter: productInfo.outer_diameter,
        inner_diameter: productInfo.inner_diameter,
        // length: productInfo.length,
        // length_unit: productInfo.length_unit,
        // width: productInfo.width,
        // width_unit: productInfo.width_unit,
        thickness: productInfo.product === "CIR" ? productInfo.thickness : 1,
        // thickness_unit: productInfo.thickness_unit,
      })
      .then((valid) => {
        api
          .post("cart/add", addToCartBody)
          .then((res) => {
            if (res.isAxiosError) {
              const errorMessage = [];
              res.response.data.errors.forEach(function (error) {
                errorMessage.push(error);
              });
              toast.error(
                `There was an error processing your request. ${errorMessage.toString()}`
              );
            } else {
              setOpenCartModal(true);
              cartDispatch({
                type: "updateCart",
                payload: {
                  cart: res.data.items,
                  cartCount: res.data.count,
                },
              });
              storage.setStorageItem("cartId", res.data.cart_id);
              gaFunctions.gaAddToCart(gaItemInfo);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((err) => {
        setErrorMessages(err.errors);
        setOpenCartModal(true);
      });
  }

  function resetToActualMarkets() {
    api.fetch(`metalbase/reset`).then((res) => {
      setShowCustomMetalMarkets(false);
    });
  }

  return (
    <>
      <Stack
        spacing={1}
        sx={{ textAlign: "right", marginBottom: "4rem", marginTop: "4rem" }}
      >
        <Typography variant="h4" sx={{ textAlign: "right" }}>
          {message ? (
            <Alert variant="filled" severity={messageType}>
              {message}
            </Alert>
          ) : (
            <Stack alignItems="end">
              {priceLoaded && validationErrors.length === 0 && authToken ? (
                productState.price ? (
                  `${formatting["price2"](productState.price)}`
                ) : (
                  "Price currently unavailable"
                )
              ) : validationErrors.length > 0 ? (
                "--------"
              ) : authToken ? (
                <Skeleton
                  variant="rectangular"
                  width="10rem"
                  height="3rem"
                  className="float-right"
                />
              ) : (
                <GAButton
                  loadingButton={false}
                  variant="contained"
                  color="error"
                  size="small"
                  styleInfo={{ marginTop: "unset" }}
                  onClickFunction={loginForPricing}
                  buttonText="Login For Pricing"
                  gaEventName="login_for_price_button"
                />
              )}
              {authToken && (
                <Tooltip
                  title="Prices and weights shown are estimates and subject to change. Most product is sold by weight and billed at daily market prices. Orders will be priced at the metal markets on the date of receipt, if order is shipped in the same day; or the markets of the day after the order is received for out of stock product. Market price is the second London fix plus a surcharge."
                  placement="left"
                >
                  <Typography
                    sx={{ paddingTop: "1rem", textDecoration: "underline" }}
                  >
                    Estimated Price
                  </Typography>
                </Tooltip>
              )}
            </Stack>
          )}
        </Typography>
        {authToken && (
          <>
            {!showCustomMetalMarkets ? (
              <Typography variant="subtitle1">Using Actual Markets</Typography>
            ) : (
              <div>
                <Typography variant="subtitle1">
                  Currently Using Custom Markets
                </Typography>
                <Typography variant="subtitle1">
                  If you place an order, we will NOT hold customized markets
                </Typography>
              </div>
            )}
            {!showCustomMetalMarkets ? (
              <Typography
                onClick={() => setShowCustomMetalMarkets(true)}
                sx={{ color: "#337ab7", textDecoration: "underline" }}
              >
                Set to custom metal markets
              </Typography>
            ) : (
              <Typography
                onClick={() => resetToActualMarkets()}
                sx={{ color: "#337ab7", textDecoration: "underline" }}
              >
                Reset to daily metal markets
              </Typography>
            )}
          </>
        )}
      </Stack>
      {authToken && (
        <Stack spacing={1} sx={{ textAlign: "right", marginBottom: "4rem" }}>
          <Typography
            variant="h5"
            sx={{ color: "#337ab7", textTransform: "uppercase" }}
          ></Typography>
          <Typography>
            Estimated ship date <strong>{estimatedShipDate}</strong> if ordered
            by 5:00pm EST
          </Typography>
          <GAButton
            loadingButton={true}
            variant="contained"
            color="success"
            styleInfo={{ width: "100%" }}
            onClickFunction={addToCart}
            buttonText="Add to Cart"
            gaEventName="add_to_cart_button"
            loading={!priceLoaded}
            loadingIndicator="Loading Price…"
            disabled={
              productState.price === 0
                ? true
                : message
                ? true
                : validationErrors.length > 0
                ? true
                : false
            }
          />
        </Stack>
      )}
      {priceBreaks.length > 0 && authToken && validationErrors.length === 0 && (
        <>
          <Stack spacing={1}>
            <div>{createPriceBreaksTable(productInfo.quantity)}</div>
          </Stack>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontWeight: "800" }}>Quantity</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: "800" }}>
                    Price Per Unit
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {priceBreaks &&
                priceBreaks.map((pb) => (
                  <TableRow key={pb.label}>
                    <TableCell>
                      <Typography>{pb.label}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {priceLoaded ? (
                          `${formatting["price2"](pb.total)}`
                        ) : (
                          <Skeleton
                            variant="text"
                            width="10rem"
                            className="float-right"
                          />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </>
      )}
      <CartModal
        errors={errorMessages}
        open={openCartmodal}
        close={setOpenCartModal}
        product={true}
      />
      <LoginModal />
    </>
  );
}
